<template>
  <div class="info-edit">
    <div class="input" @click="showSexPicker=true">
      <label>{{$t('text146')}}</label>
      <p :class="{active: form.gender !== null}">{{ form.gender === null ? this.$t('text147') : sex[form.gender].text }} <van-icon name="arrow" /></p>
    </div>
    <div class="input">
      <label>{{$t('text148')}}</label>
      <input v-model="form.age" type="text" :placeholder="$t('text149')">
    </div>
    <div class="input">
      <label>{{$t('text150')}}</label>
      <input v-model="form.height" type="text" :placeholder="$t('text151')">
    </div>
    <div class="input">
      <label>{{$t('text152')}}</label>
      <input v-model="form.weight" type="text" :placeholder="$t('text153')">
    </div>
    <div class="input" @click="showConsPicker=true">
      <label>{{$t('text154')}}</label>
      <p :class="{active: form.constellation}">{{ form.constellation || this.$t('text155') }} <van-icon name="arrow" /></p>
    </div>
    <div class="input">
      <label>{{$t('text156')}}</label>
      <input v-model="form.career" type="text" :placeholder="$t('text157')">
    </div>
    <div class="input">
      <label>{{$t('text158')}}</label>
      <input v-model="form.city" type="text" :placeholder="$t('text159')">
    </div>
    <h4>{{$t('text160')}}</h4>
    <textarea :placeholder="$t('text161')" v-model="form.signature"></textarea>
    <div class="btn" @click="submit">{{$t('text162')}}</div>
    <van-popup v-model="showSexPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="sex"
        @confirm="confirmSex"
      />
    </van-popup>
    <van-popup v-model="showConsPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="constellations"
        @confirm="confirmCon"
      />
    </van-popup>
  </div>
</template>

<script>
import { post } from '../../common/js/http'

export default {
  name: 'info-edit',
  data() {
    return {
      showSexPicker: false,
      showConsPicker: false,
      sex: [{ text: this.$t('text163'), type: 0 }, { text: this.$t('text164'), type: 1 }],
      constellations: [this.$t('text165'), this.$t('text166'), this.$t('text167'), this.$t('text168'), this.$t('text169'), this.$t('text170'), this.$t('text171'), this.$t('text172'), this.$t('text173'), this.$t('text174'), this.$t('text175'), this.$t('text176')],
      form: {
        gender: null,
        age: '',
        height: '',
        weight: '',
        constellation: '',
        city: '',
        signature: '',
        career: ''
      }
    }
  },
  methods: {
    confirmSex(res) {
      this.form.gender = res.type
      this.showSexPicker = false
    },
    confirmCon(res) {
      this.form.constellation = res
      this.showConsPicker = false
    },
    submit() {
      post('/api/User/editUserInfo', this.form)
        .then(() => {
          this.$router.back()
        })
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../common/style/variable.styl"
  .info-edit
    .input
      padding 0 30px
      height 123px
      display flex
      align-items center
      border-bottom 1px solid #F4F4F4
      &>label
        font-size 30px
        color #666666
      &>p
        flex 1
        display flex
        align-items center
        justify-content flex-end
        font-size 30px
        color #B4B4B4
        &.active
          color #000
      &>input
        flex 1
        text-align right
        font-size 30px
        color #000
        &::-webkit-input-placeholder
          color #B4B4B4
    &>h4
      margin-bottom 45px
      padding 0 30px
      padding-top 48px
      font-size 30px
      color #666666
    &>textarea
      width 100%
      height 108px
      line-height 36px
      box-sizing border-box
      padding 0 30px
      font-size 30px
      &::-webkit-input-placeholder
        color #B4B4B4
    .btn
      margin 0 61px
      margin-bottom 54px
      height 100px
      line-height 100px
      text-align center
      border-radius 50px
      background $theme-color
      font-size 32px
      color #FEFFFF
</style>
